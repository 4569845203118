<template>
    <div>
        <el-button class="btnAdd" size="small" type="primary" @click="addDataInfo()">新增</el-button>
        <el-button class="btnAdd" size="small" type="primary" @click="refreshDataInfo()">刷新数据字典</el-button>
<!--        <el-button class="btnSearch" size="small" type="primary" @click="downloadDataInfo()">导出</el-button>-->

        <el-table ref="multipleTable" tooltip-effect="dark" :data="tableData"
                  style="width: 100%;" :height="tableHeight" >
            <el-table-column prop="id" label="编号" type="index" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="dataName" label="字典类型名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="dataCode" label="字典类型编码" show-overflow-tooltip></el-table-column>
<!--            <el-table-column prop="enableMark" label="是否启用" show-overflow-tooltip ></el-table-column>-->
            <el-table-column prop="createtime" label="创建时间" show-overflow-tooltip :formatter="dateFormat"></el-table-column>
            <el-table-column prop="updatetime" label="更新时间" show-overflow-tooltip :formatter="dateFormat"></el-table-column>
            <el-table-column prop="manage" label="管理">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" class="el-icon-primary"
                               @click="handleAdd(scope.$index, scope.row)">添加</el-button>
                    <el-button size="mini" type="primary" class="el-icon-primary"
                               @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" class="el-icon-delete"
                               @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(totalCount)">
        </el-pagination>
        <el-dialog title="新增数据字典" :visible.sync="insertDialogVisible" width="40%">
            <el-form :model="form" label-width="100px" size="small">
                <el-form-item label="字典类型名称:">
                    <el-input v-model="form.dataName"></el-input>
                </el-form-item>
                <el-form-item label="字典类型编码:">
                    <el-input v-model="form.dataCode"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="insertDataInfo()">提 交</el-button>
                <el-button @click="insertDialogVisible = false;cancel();">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="新增数据字典字段" :visible.sync="insertDetailDialogVisible" width="40%">
            <el-form :model="detailForm" label-width="100px" size="small">
                <el-form-item label="字典字段名称:">
                    <el-input v-model="detailForm.dataName"></el-input>
                </el-form-item>
                <el-form-item label="字典字段编码:">
                    <el-input v-model="detailForm.dataCode"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="insertDataInfoDetail()">提 交</el-button>
                <el-button @click="insertDetailDialogVisible = false;cancelDetail();">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="字典维护" :visible.sync="editDialogVisible" width="60%">
            <el-table ref="multipleTable" tooltip-effect="dark" :data="dialogTableData"
                      style="width: 100%;" :height="dialogTableHeight" >
                <el-table-column prop="id" label="编号" type="index" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="dataName" label="字典字段名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="dataCode" label="字典字段编码" show-overflow-tooltip></el-table-column>
                <el-table-column prop="enableMark" label="是否启用" :formatter="formatter" show-overflow-tooltip ></el-table-column>
                <el-table-column prop="manage" label="管理">
                    <template slot-scope="scope">
                        <el-button size="mini" type="warning"
                                   @click="handleDialogDisable(scope.$index, scope.row)">启用/停用</el-button>
                        <el-button size="mini" type="danger"
                                   @click="handleDialogDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
<!--                <el-button type="primary" @click="updateEquipment()">提 交</el-button>-->
                <el-button @click="editDialogVisible = false;">完 成</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {fDateTimeFormat, fLoadAllDataInfo} from "@/utils/utilsset";
import {sanyouAxios} from "@/api";
export default {
    name: "operationlog",
    data() {
        return {
            currentUserId:"",
            //表格高度
            tableHeight:500,
            //表格数据
            tableData:[],
            //分页
            currentPage:1,
            pageSize: 10,
            totalCount:0,
            //字典的表单
            form:{
                dataName:'',
                dataCode:'',
                dataDescription:'',
                enableMark:'',
            },
            //字典字段的表单
            detailForm:{
                dataName:'',
                dataCode:'',
                parentCode:'',
                parentId:'',
                dataDescription:'',
                enableMark:'',
            },
            statusList: [
                {
                    value: 1,
                    label: "启用",
                },
                {
                    value: 0,
                    label: "禁用",
                },
            ],
            //新增字典弹窗
            insertDialogVisible:false,
            //新增字典字段弹窗
            insertDetailDialogVisible:false,
            //字典维护弹窗
            editDialogVisible: false,
            //字典维护弹窗的表格数据
            dialogTableData:[],
            //字典维护弹窗表格高度
            dialogTableHeight:400,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el['offsetTop'] - 180;
            window.onresize = () => {
                this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el['offsetTop'] - 180;
            };
        });
    },
    methods: {

        //每页显示数据量变更
        handleSizeChange: function (val) {
            this.pageSize = val;
            let currentPage = this.currentPage;

            this.loadData(currentPage,val);
        },
        //页码变更
        handleCurrentChange: function (val) {
            this.currentPage = val;
            let pageSize = this.pageSize;
            this.loadData(val,pageSize);
        },
        dateFormat:function(row,column){
            let date = row[column.property];
            if(!date){
                return ''
            }
            return fDateTimeFormat(date);//moment(date).format("YYYY-MM-DD HH:mm:ss")
        },
        //加载数据字典数据
        loadData: function(page, pageSize){
            let _this = this;

            if(page == null || isNaN(page))
                page = 1;
            if(pageSize == null || isNaN(pageSize))
                pageSize = 10;

            sanyouAxios({
                /*headers:{
                    userId:this.currentUserId
                },*/
                method: 'POST',
                url:'/dataInfo/getDataInfos?page=' + page + '&pageSize=' + pageSize,
            }).then(res => {
                if(res.data.status === 200){
                    let data = res.data.data;
                    _this.tableData = data.rows;
                    _this.totalCount = data.records;
                }else{
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: '没有权限查看!'
                    });
                }
            });
        },

        //导出
        downloadDataInfo(){
            //undo
            this.$message({
                duration: 2000,
                message: '暂未开放',
                type: 'warning'
            });
        },
        //新增按钮-点击事件
        addDataInfo(){
            //先清空
            this.cancel();
            this.insertDialogVisible=true;

        },
        //新增-请求后台
        insertDataInfo(){
            /*//判空
            if(!this.form.dataName||!this.form.dataCode)
            {
                this.$message({
                    type: 'warning',
                    duration: 2000,
                    message: '请填入必要数值',
                });
                return;
            }*/
            sanyouAxios({
                headers:{
                    userId:this.currentUserId
                },
                method: 'POST',
                url: '/dataInfo/insertDataInfo',
                data: {
                    dataName: this.form.dataName,
                    dataCode: this.form.dataCode,
                }
            }).then(res => {
                if(res.data.status === 200){
                    this.$message({
                        duration: 2000,
                        message: res.data.data,
                        type: "success",
                    });
                }else{
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: res.data.msg,
                    });
                }

                let currentPage = this.currentPage;
                let pageSize = this.pageSize;
                this.loadData(currentPage,pageSize);

                this.cancel();
                this.insertDialogVisible = false;
            });
        },
        //新增字段DataInfoDetail
        handleAdd(index,row){
            //清空
            this.cancelDetail();
            this.insertDetailDialogVisible=true;
            this.detailForm.parentCode=row['dataCode'];
            this.detailForm.parentId=row['id'];
        },
        //新增字段-请求后台
        insertDataInfoDetail(){
            /*//判空
            if(!this.form.dataName||!this.form.dataCode)
            {
                this.$message({
                    type: 'warning',
                    duration: 2000,
                    message: '请填入必要数值',
                });
                return;
            }*/
            sanyouAxios({
                headers:{
                    userId:this.currentUserId
                },
                method: 'POST',
                url: '/dataInfo/insertDataInfoDetail',
                data: {
                    dataName: this.detailForm.dataName,
                    dataCode: this.detailForm.dataCode,
                    parentCode: this.detailForm.parentCode,
                    parentId: this.detailForm.parentId,
                }
            }).then(res => {
                if(res.data.status === 200){
                    this.$message({
                        duration: 2000,
                        message: res.data.data,
                        type: "success",
                    });
                }else{
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: res.data.msg,
                    });
                }

                this.cancelDetail();
                this.insertDetailDialogVisible = false;
            });
        },
        //编辑
        handleEdit(index,row){
            sanyouAxios({
                /*headers:{
                    userId:this.currentUserId
                },*/
                method: 'POST',
                url: '/dataInfo/getDataInfoDetailsById',
                params: {
                    id: row['id'],
                }
            }).then(res => {
                if(res.data.status === 200){
                    //弹窗
                    let data = res.data.data;
                    this.editDialogVisible=true;
                    //console.log(data);
                    this.dialogTableData=data;
                }else{
                    this.$message({
                        type: 'warning',
                        duration: 2000,
                        message: res.data.msg,
                    });
                }

                /*let currentPage = this.currentPage;
                let pageSize = this.pageSize;
                this.loadData(currentPage,pageSize);*/
            });
        },
        //删除
        handleDelete(index,row){
            this.$confirm('此操作将删除该数据字典, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                sanyouAxios({
                    headers:{
                        userId:this.currentUserId
                    },
                    method: 'POST',
                    url: '/dataInfo/deleteDataInfo',
                    params: {
                        id: row.id,
                        //deleteMark: 1
                    }
                }).then(res => {
                        //console.log(res);
                        if(res.data.status === 200){
                            this.$message({
                                type: 'success',
                                duration: 2000,
                                message: '删除成功!'
                            });
                        }else{
                            this.$message({
                                duration: 2000,
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                        let currentPage = this.currentPage;
                        let pageSize = this.pageSize;
                        this.loadData(currentPage,pageSize);
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 2000,
                    message: '已取消删除'
                });
            });
        },
        //取消
        cancel() {
            //清空内容
            this.form = {
                dataName:'',
                dataCode:'',
                dataDescription:'',
                enableMark:'',
            };
        },
        //取消字段弹窗
        cancelDetail() {
            //清空内容
            this.detailForm = {
                dataName:'',
                dataCode:'',
                parentCode:'',
                parentId:'',
                dataDescription:'',
                enableMark:'',
            };
        },
        //数据字典弹窗-停用
        handleDialogDisable(index,row){
            //undo
            if(Number(row.enableMark)===1){
                //禁用
                sanyouAxios({
                    headers:{
                        userId:this.currentUserId
                    },
                    method: 'POST',
                    url: '/dataInfo/disableDataInfoDetail',
                    params: {
                        id: row.id
                    }
                }).then(res => {
                    if(res.data.status === 200){
                        this.$message({
                            duration: 2000,
                            message: res.data.data,
                            type: "success",
                        });
                        //row.enableMark=(Number(row.enableMark)+1)%2
                        row.enableMark=0;
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: res.data.msg,
                        });
                    }
                });
            } else {
                //启用
                sanyouAxios({
                    headers:{
                        userId:this.currentUserId
                    },
                    method: 'POST',
                    url: '/dataInfo/enableDataInfoDetail',
                    params: {
                        id: row.id
                    }
                }).then(res => {
                    if(res.data.status === 200){
                        this.$message({
                            duration: 2000,
                            message: res.data.data,
                            type: "success",
                        });
                        //row.enableMark=(Number(row.enableMark)+1)%2
                        row.enableMark=1;
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: res.data.msg,
                        });
                    }
                });
            }

        },
        //数据字典弹窗-删除
        handleDialogDelete(index,row){
            let that=this;
            this.$confirm('此操作将删除该数据字典字段, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                sanyouAxios({
                    headers:{
                        userId:this.currentUserId
                    },
                    method: 'POST',
                    url: '/dataInfo/deleteDataInfoDetail',
                    params: {
                        id: row.id,
                        //deleteMark: 1
                    }
                }).then(res => {
                    //console.log(res);
                    if(res.data.status === 200){
                        this.$message({
                            type: 'success',
                            duration: 2000,
                            message: res.data.data
                        });
                    }else{
                        this.$message({
                            duration: 2000,
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                    /*let currentPage = this.currentPage;
                    let pageSize = this.pageSize;
                    this.loadData(currentPage,pageSize);*/
                    that.editDialogVisible=false;

                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 2000,
                    message: '已取消删除'
                });
            });
        },
        //用于表格的映射渲染
        formatter(row, column) {
            return this.statusList.find((item)=>{
                return Number(item.value)===Number(row.enableMark)
            })['label'];
            //return row.enableMark;
        },
        //刷新数据字典
        refreshDataInfo(){
            fLoadAllDataInfo(sanyouAxios,this.$message);
        }
    },

    created() {
        this.currentUserId = localStorage.getItem('id');

        let currentPage = this.currentPage;
        let pageSize = this.pageSize;
        this.loadData(currentPage,pageSize);
    }
};
</script>
<style scoped>
.btnAdd {
    margin: 10px 10px;
    float: left;
}
/*.btnDelete {
    margin: 10px 10px;
    float: left;
}
.btnSearch {
    margin: 10px 10px;
    float: right;
}.inputSearch {
    float: right;
    width: 250px;
    padding-right: 10px;
    padding-top: 5px;
}*/
</style>
